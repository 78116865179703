import { TranslationProvider, useTranslation } from '@/src/contexts/translation.context';
import React, { Component, useState } from 'react';
import Header from '@/src/components/header/header';
import Footer from '@/src/components/footer/footer';
import Navbar from '@/src/components/navbar/navbar';
import { Link, PageProps } from 'gatsby';
import { TranslationService } from '@/src/services/translation.service';
import * as styles from './index.module.scss';
import { Badge } from '@/src/components/badge/badge';
import Slider from 'react-slick';
import ActivityListItem from '../activities/activity-list-item';
import BottomSheet from '@/src/components/bottom-sheet/bottom-sheet';
import SignupFunnel from '@/src/components/signup-funnel/signup-funnel';
import { useEffect } from 'react';
import { CustomImage } from '@/src/components/image/image';
import { Helmet } from "react-helmet";

export default function IndexPage({ pageContext }: PageProps) {
  const [translation] = useState(new TranslationService(pageContext));
  const [funnelOpen, setFunnelOpen] = useState(false);
  const [matchHeight, setMatchHeight] = useState(null);

  const activities: any = (pageContext as any).activities;
  const filteredActivities = [];
  let counter = 0;
  activities.map((activity) => {
    if(counter === 12) {
      return false;
    }

    if(activity.images[0]) {
      filteredActivities.push(activity);
      counter++;
    }
  });

  const slickSettings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToScroll: 1,
    touchThreshold: 20,
    centerMode: true,
    centerPadding: '50px',
    slidesToShow: 9,
    responsive: [
      {
        breakpoint: 9999,
        settings: 'unslick',
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          dots: false,
          infinite: true,
          speed: 1000,
          slidesToScroll: 1,
          touchThreshold: 20,
          centerMode: true,
          centerPadding: '50px',
          slidesToShow: 1,
        }
      }
    ]
  };

  useEffect(() => {
    (async () => {
      if(!matchHeight) {
        const MatchHeight = (await import('matchheight')).default;
        setMatchHeight(MatchHeight);
      }
    })();
  }, []);

  useEffect(() => {
    if(matchHeight) {
      matchHeight.init();
    }
  }, [matchHeight, filteredActivities]);

  return (
    <TranslationProvider value={translation}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{translation.translate('helmet.index_title')}</title>
        <meta name="description" content={translation.translate('helmet.index_description')} />
        <meta http-equiv="content-language" content={translation.lang} />
      </Helmet>
      <Header></Header>
      <div className={styles['home__wrapper'] + ' scrollable'}>
        <div className="app__wrapper">
          <div className={styles.home__intro}>
            <h1>{translation.translate('home.intro.heading')}</h1>
            <p>{translation.translate('home.intro.description')}</p>
            <div className={styles.home__introGridWrapper}>
              <div className={styles.home__introGridInner}>
                <div className={styles.home__introGrid}>
                  <div className={styles.home__introGridImage + ' ' + styles.home__introGridImage1}>
                    <CustomImage src="homepage/grid-1-2.jpg" width={384} height={645}></CustomImage>
                  </div>
                  <div className={styles.home__introGridImage + ' ' + styles.home__introGridImage2}>
                    <CustomImage src="homepage/grid-2-2.jpg" width={384} height={384}></CustomImage>
                  </div>
                  <div className={styles.home__introGridImage + ' ' + styles.home__introGridImage3}>
                    <CustomImage src="homepage/grid-3-2.jpg" width={1231} height={645}></CustomImage>
                  </div>
                  <div className={styles.home__introGridImage + ' ' + styles.home__introGridImage4}>
                    <CustomImage src="homepage/grid-4-2.jpg" width={807} height={416}></CustomImage>
                  </div>
                  <div className={styles.home__introGridImage + ' ' + styles.home__introGridImage5}>
                    <CustomImage src="homepage/grid-5-2.jpg" width={807} height={416}></CustomImage>
                  </div>
                  <div className={styles.home__introGridImage + ' ' + styles.home__introGridImage6}>
                    <CustomImage src="homepage/grid-6-2.jpg" width={384} height={416}></CustomImage>
                  </div>
                  <div className={styles.home__introGridImage + ' ' + styles.home__introGridImage7}>
                    <CustomImage src="homepage/grid-7-2.jpg" width={807} height={645}></CustomImage>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles['home__activities']}>
            <h2 dangerouslySetInnerHTML={{ __html: translation.translate('home.activities.heading') }}></h2>
            <div className={styles.singleActivity__mediaCarousel}>
              <Slider {...slickSettings as any}>
                {filteredActivities.map((activity, index) => {
                  const heading = activity.title[translation.lang];
                  const free = activity.free;
                  const location = activity.location;
                  const imageUrl = activity.images[0] ? `uploads/${activity.id}/image/${activity.images[0].id}` : null;

                  // Change subheading and description if the activity is free or not 
                  let subheading = null;
                  let description = null;
                  if(free) {
                    subheading = translation.translate('activities.free_activity');
                  } else if(activity.offers.length > 0) {
                    const offers = activity.offers;

                    if(offers.length > 0) {
                      subheading = offers[0].title[translation.lang];

                      if(offers[0].amount.length > 0) {
                        description = offers[0].amount;
                      } else {
                        let percentageOfferSuffix = translation.translate('activities.percentage_offer_suffix');
                        description = '-' + offers[0].percentage + '% ' + percentageOfferSuffix;
                      }
                    }
                  }

                  const href = `/${translation.lang}/activities/${activity.company.slug}/${activity.slug[translation.lang]}`;

                  return (
                    <div key={activity.id} className={styles.home__activitiesCard}>
                      <ActivityListItem
                        translation={translation}
                        heading={heading}
                        subheading={subheading}
                        description={description}
                        hasImage={!!imageUrl}
                        imageUrl={imageUrl}
                        locations={location}
                        href={href}
                        activityId={activity.id}
                        styling="card"
                        onFailedFunnelTest={() => setFunnelOpen(true)}></ActivityListItem>
                    </div>
                  );
                })}
              </Slider>
            </div>
            <Link to={`/${translation.lang}/activities`} className={styles.home__activitiesLink}>
              <Badge.Filled text={translation.translate('home.activities.btn_text')}></Badge.Filled>
            </Link>
          </div>
          <div className={styles.home__passesPreviewWrapper}>
            <div className={styles.home__passesPreview}>
              <h2 dangerouslySetInnerHTML={{ __html: translation.translate('home.passes_preview.heading') }}></h2>
              <p className={styles.home__passesPreviewDescription}>{translation.translate('home.passes_preview.description')}</p>
              <div className={styles.home__passesPreviewCard}>
                <h3>{translation.translate('home.passes_preview.buss_ferry.heading')}</h3>
                <p>{translation.translate('home.passes_preview.buss_ferry.description')}</p>
                <ul>
                  <li>{translation.translate('home.passes_preview.buss_ferry.bullet_1')}</li>
                  <li>{translation.translate('home.passes_preview.buss_ferry.bullet_2')}</li>
                  <li>{translation.translate('home.passes_preview.buss_ferry.bullet_3')}</li>
                </ul>
                <Link to={`/${translation.lang}/book-ticket`}>
                  <Badge.Filled text={translation.translate('home.passes_preview.buss_ferry.btn_text')}></Badge.Filled>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <BottomSheet open={funnelOpen}>
          <SignupFunnel
            onClose={() => setFunnelOpen(false)}
            actionKey="favourite_activity"></SignupFunnel>
        </BottomSheet>
      </div>

      <Navbar></Navbar>
    </TranslationProvider>
  );
}